.Education {
    margin-top: 5.8%;
}

.row1 {
    display: flex;
    justify-content: space-between;
    margin: 0 3.5%;
}

.row2 {
    display: flex;
    flex-direction:column;
    margin: 0 auto;
    text-align: center;
}

.row3 {
    display: flex;
    justify-content: space-around;
}

.row3_button {
    margin-left: 3.5%;
    margin-bottom: 5%;
}

.heading {
    font-size: 4.5rem;
    color: white;
    font-weight: 200;
    margin: 0;
    text-align: center;
}


.circle1{
    height: 124px;
    width: 124px;
    border-radius: 50%;
}

.circle2{
    height: 97px;
    width: 97px;
    border-radius: 50%;
}

.circle3{
    height: 101px;
    width: 101px;
    border-radius: 50%;
}

.circle4{
    height: 166px;
    width: 166px;
    border-radius: 50%;
}

@media (max-width: 898px) {
    .heading {
      font-size: 2rem;
      margin-top: 5%;
    }
}

.caption {
    color: #7F89BE;
    font-size: 1rem;
    margin-bottom: 2%;
}

.caption2 {
    color: #7F89BE;
    font-size: 1.5rem;
    margin-bottom: 10%;
    text-align: center;
}

@media (max-width: 898px) {
    .caption2{
      font-size: 1rem;
      text-align: left;
    }
}

.gradient_text {
    font-weight: 600;
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#0aaee0, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}




.section2{
    margin: 10% 9%;
}


.section2_heading {
    width: 55%;
    margin: 0 auto;
}

@media (max-width: 898px) {
    .section2_heading {
      font-size: 2rem;
      margin: 0;
    }

    .align_left {
        text-align: left;
    }
    
}



.card_container {
    width: 100%;
}

.card_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5%;
}


@media (max-width: 898px) {
    .card_row {
        flex-wrap: wrap;
        margin-top: 0;
    }
}



.Screens {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10%;
}

@media (max-width: 898px) {
    .Screens {
        flex-wrap: wrap;
    }
}


.text_box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 9%;
}


@media (max-width: 898px) {
    .text_box2 {
        margin: 0 9%;
    }
}

.heading2 {
    font-size: 3rem;
    color: var(--text-primary);
    font-weight: 200;
    margin: 0;
}

.text2 {
    color: var(--secondary);
    font-size: 1rem;
    margin-top: 4%;
    margin-bottom: 6%;
}


.button_arrow {
    font-size: 1.5rem;
    padding: 1rem;
 }
 .heading1 {
    display: flex;
    flex-direction: column;
  width: 100%;
    margin: 2vh auto;
    margin-bottom: 2rem;
}


.heading1_h {
    font-size: 5.3rem;
    color: var(--heading-color1); 
     width: 50%;
     margin: 0 auto;
    text-align: center;
    font-family: Poppins;
font-style: normal;
font-weight: 100;
    margin-bottom: 1%;
    margin-top: 4vh ;
}

.searchContainer{
    width: 70%;
    justify-content: center;
    align-content: center;
    margin: 4rem auto;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    background:  var(--backgroud-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.seachBox{
    width: 100%;
    height: 4rem;
    background:  var(--backgroud-color);
    border:none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    padding-left: 1.5rem;
}
.seachBox:focus , .seachBox:active , .seachBox:focus-visible{
    outline: none;
}
.searchBtn{
    width: 6rem;
    background:  var(--button-gradient1);
    border: none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

}

.bold {
    font-weight: 600; 
}
@media (max-width: 798px) {
    
    .heading1_h{
        font-size: 3.3rem;
    }
}
@media only screen and (min-width: 779px) and (max-width: 1188px){
   
    .heading1_h{
        font-size: 3.3rem;
    }
  }