.feature_card {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    color: white;
    border-radius: 3.7rem;
    padding: 4rem 4vw;
    justify-content: space-around;
    color: #D9DEF4;
    margin: 2rem 2rem;  
    animation: 1s;
    transition-duration: 1s;
    width: 600px;
    height: 250px;
}

@media (max-width: 1500px) {

    .feature_card {
        width: 560px;
        height: 235px;
        padding: 4.2rem 2.5vw;
        margin: 2rem 1.5rem;
    }
}

@media (max-width: 1320px) {

    .feature_card {
        width: 470px;
        height: 220px;
    }
}

@media (max-width: 1230px) {

    .feature_card {
        width: 400px;
        height: 220px;
    }

}
@media (max-width: 1080px) {

    .feature_card {
        width: 360px;
        height: 200px;
        padding: 4rem 2.5vw;
    }

}
@media (max-width: 980px) {

    .feature_card {
        width: 310px;
        height: 270px;
        padding: 3rem 1.9vw;
    }

}


@media (max-width: 786px) {

    .feature_card {
        width: 342px;
        height: 180px;
        border-radius: 2.5rem;
        margin: 0;
    }
}

@media (max-width: 500px) {

    .feature_card {
        width: 300px;
        height: 180px;
    }
}

@media (max-width: 400px) {

    .feature_card {
        width: 260px;
    }
}

.rotate_left {
    transform: rotate(-6.13deg);
    margin-top: 10%;
}

.rotate_right {
    transform: rotate(5.87deg);
    margin-top: 10%;
}




.red_border {
    border: 1px solid #E42A5B;
}

.green_border {
    border: 1px solid #BDF2A5;
}

.yellow_border {
    border: 1px solid #F5D495;
}

.blue_border {
    border: 1px solid #00C6FF;
}

.username {
    font-size: 1rem;
    margin-bottom: 10%;
}

@media (min-width: 1200px) {
    .username {
        margin-bottom: 5%;
    }
}
.hashtag {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.content {
    font-size: 1rem;
    margin-bottom: 10%;
    font-weight: 500;
}

@media (min-width: 1300px) {
    .content {
        margin-bottom: 5%;
    }
}

@media (max-width: 786px) {

    .username {
        font-size: 12px;
    }

    .hashtag {
        font-size: 1.25rem;
    }
    
    .content {
        font-size: 12px;
        font-weight: 400;
    }
}


.info_box {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
}

@media (max-width: 1320px) {

    .info_box {
        font-size: 1rem;
    }
}

.info {
    display: flex;
    justify-content: space-around;
}


.info_element {
    display: flex;
    margin-left: 4rem;
    justify-content: space-between;
}

.info_text{
    padding-left: 6px; 
}



@media (max-width: 1200px) {

    .info_element{
        margin-left: 2rem;
    }
    .icon {
        height: 15px;
    }

    .info_text{
        padding-left: 6px; 
        font-size: 12px;
        margin-top: 0;
    }
    
}



