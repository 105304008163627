.addContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  
}
.statusList {
  width: 100%;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  height: 90%;
  overflow-y: auto;
  margin-bottom: 5.4rem;
}
.searchBox {
  padding: 0.35rem 0.4rem;
  bottom: 0px;
  left: 0;
  align-items: center;
  position: absolute;
  display: flex;
  width: 91%;
  border: 1px solid var(--secondary);
  border-radius: 8px;
}

.mobileSearchBox {
  margin: 0.7rem 0;
  width: 100%;
  display: flex;
  padding: 0 0.5rem;
}

.mobileSearchBox .searchField {
  font-size: 1rem;
}

.searchField {
  flex: 1;
  border: none;
  z-index: 0;
  position: relative;
  border-radius: 4px;
  font-size: 13px;
  padding:0.2rem ;
  padding-left: 0.5rem;
  background:  var(--backgroud-color);
}
input::placeholder{
  color: var(--secondary);
}

.statusList::-webkit-scrollbar {
  /* width: 0; */
}

.addStatusContainer {
  display: flex;
  width: 100%;
  margin-left: 0.2rem;
  padding: 1rem 0 2.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
}
.thoughts{
  display: flex;
  flex: 1;
  text-align: left;
  font-size: 1.2rem;
  justify-content: left;
  margin-top: 0.5rem;
}
.thoughtsHead{
  color: var(--heading-color1);
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 0.4rem;
}
.tutorialCont {
  /* background: var(--heading-color2) !important; */
}

.tutorialText {
  padding: 0.7rem;
  font-size: 0.9rem;
  font-weight: 400;

  font-family:  var(font-family) !important;
}
.feednames{
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  padding: 0.5rem;
  background: #121528;
box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.06);
border-radius: 200px;
}
@media screen and (max-width: 768px) {
  .sidebarContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .addStatusContainer {
    display: none;
  }
}
