.left {
    border-radius: 100rem 100rem 100rem 0rem;
    z-index: 5;
    padding-right: 6%;
}


.right {
    border-radius: 100rem 100rem 0rem 100rem;
    z-index: 5;
}