.formContainer{
    display: flex;
    flex-direction: row;
    background-color: var(--backgroud-color) ;
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.5);
    border-radius: 24px;
    margin: 3rem 9vw;
    padding: 1.5rem 2rem;
}
.left{
    display: flex;
    flex-direction: column;
    width: 25%;
}
.rightForm{
    display: flex;
    flex-direction: column;
    margin-left: 6vw;
    margin-top: 0.5rem;
    width: 67%;
}
.heading{
    margin:3rem 0;
}
h1{
 font-style: normal;
font-weight: 200;
font-size: 3.5rem;
color: var(--heading-color2);
}
.meet {
 font-style: normal;
font-weight: 600;
font-size: 3.5rem;
background: -webkit-linear-gradient(var(--text-gradient));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
color: var(--button-gradient1) !important;
}
.to {
    font-style: normal;
   font-weight: 600;
   font-size: 3.5rem;
   color: var(--text-purple) !important;
}
.iconsLeft{
    color: var(--text-primary);
    margin: 0.5rem 0;
    font-style: normal;
    font-weight: 200;
    font-size: 1.1rem;
    text-decoration: none;
}
.iconsLeft img {
    margin-top: 0.2rem;
}
.iconsLeft span {
    margin-top: -0.4rem;
    margin-left: 0.2rem;
}
.head{
    font-style: normal;
    font-weight: 200;
    font-size: 1.1rem;
    width: fit-content;
    padding-bottom: 0.18rem;
    border-bottom: 2px rgba(0, 200, 255, 0.705) solid; 
    margin-bottom: 5rem;
    color: var(--heading-color2);
}
.sectionHead{
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    margin-bottom:1rem;
    color: var(--heading-color2);
}
.fields{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0.5rem 0;
}
.icons{
    display: flex;
    flex-direction: row;
    margin-top: 1.9rem;
}
.icon{
    margin-left: 0.8rem;
}
@media only screen and (max-width: 868px){
    .formContainer{
        flex-direction: column;
        background-color: var(--backgroud-color) ;
        box-shadow: none;
        border-radius: 0px;
        margin: 2rem 1vw;
        padding: 1.5rem 1rem;
    }
    .logo{
        display: none;
    }
    .heading{
        margin:1rem 0;
    }
    .heading h1{
        width: 95%;
        margin: 0 0 ;
        text-align: left;
        font-size: 2rem;
    }
    .meet {
       font-size: 2rem; 
       }
       .to {
          font-size: 2rem;
       }
       .left{
        display: flex;
        flex-direction: column;
        width: 95%;
    }
    .rightForm{
        display: flex;
        flex-direction: column;
        margin-left: 2vw;
        margin-top: 0.5rem;
        width: 98%;
    }
    .head{
        font-size: 1.2rem;
        width: fit-content;
        padding-bottom: 0.19rem;
        border-bottom: 2px rgba(0, 200, 255, 0.705) solid; 
        margin-bottom: -0.5rem;
        margin-top: 5rem;
        color: var(--heading-color2);
    }
    .fields{
        flex-direction: column;
        margin: 0 0;
    }
}
@media only screen and (min-width: 789px) and (max-width: 1188px){
   
    .heading h1{
        font-size: 3rem;
    }
  }