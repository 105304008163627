.container {
    width: 100%;
    min-height: 100vh;
    background: #BDF2A5;
    display: flex;
    justify-content: space-around;
    max-height: 1000px;
}

@media (min-height: 1500px) {

    .container {
        min-height: 1000px;
    }
}


.row4 {
    width: 80%;
    min-height: 100%;
    display: flex;
    justify-content: space-around;
    height: 100%;
    margin: auto auto;
}

.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10%;
    color: black;
}

.title{
    font-weight: 200;
    font-size: 3.5rem;
    margin-bottom: 1%;
    color: black;
}

.caption{
    font-weight: 400;
    font-size: 1.5rem;
}

.bold {
    font-weight: 600;
}


@media (max-width: 768px) {
    .row4 {
        flex-direction: column;
        flex-flow: column-reverse;
    }

    .title {
        font-size: 2rem;
    }

    .image {
        height: 276px;
    }
}
@media only screen and (min-width: 779px) and (max-width: 1188px){
    .title {
        font-size: 4rem;
    }
    .caption{
        font-size: 1.2rem;
    }
    .image {
        margin: auto 0;
        margin-top: 35%;
        height: 286px;
    }
}