.row1 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin: 0 9%;
    max-height: 600px;
    min-height: 90vh;
  
}

@media (min-height: 1500px) {

    .row1 {
        min-height: 30vh;
    }
}


.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title{
    font-weight: 200;
    font-size: 4.5rem;
    margin-bottom: 1%;
    margin-top: 0;
}

.caption{
    font-weight: 400;
    font-size: 1.5rem;
}

.image {
    margin: auto 0;
    height: 568px;
}

@media (min-height: 900px) {

    .image {
        height: 800px;
    }
}

@media (min-height: 1500px) {

    .image {
        height: 590px;
    }
}

.gradient_text {
    font-weight: 600;
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#8b0ae0, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}



@media (max-width: 798px) {
    .row1 {
        flex-wrap: wrap;
    }

    .title {
        font-size: 2rem;
    }

    .image {
        margin: 0 auto;
        height: 276px;
    }
}
@media only screen and (min-width: 779px) and (max-width: 1188px){
    .title {
        font-size: 4rem;
    }
    .caption{
        font-size: 1.2rem;
    }
    .image {
        margin: auto 0;
        margin-top: 27%;
        height: 296px;
    }
}


@media (max-width: 450px) {
    .image {
        height: 230px;
    }
}

@media (max-width: 450px) {

    .image {
        height: 230px;
    }
}


@media (max-width: 360px) {

    .image {
        height: 180px;
    }
}
