.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 1000px;
    margin-top: 10%;
    margin-bottom: 15%;
}

.row {
    width: 80%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin: auto auto;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }
}

.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15%;
    color: var(--text-primary);
}

@media (max-width: 768px) {
    .textbox {
        margin: 0;
    }
}


.title{
    font-weight: 200;
    font-size: 4.5rem;
    margin-bottom: 1%;
    color: var(--text-primary);
}


@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }
}

.caption{
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--secondary);
}

@media (max-width: 768px) {
    .caption {
        font-size: 1rem;
    }
}

.gradient_text {
    font-weight: 600;
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#FC4668, #3F5EFB);    
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     margin: 0px;
}



@media (max-width: 768px) {
    .image {
        height: 220px;
    }
}
