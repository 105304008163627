.color_card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 26%;
}

@media only screen and (max-width: 786px){
    .color_card_container {
        width: 100%;
        margin-bottom: 5%;
    }

    .flex_row {
        flex-direction: row;
    }

    .flex_row_reverse {
        flex-direction: row-reverse;
    }
}

.Color_Card {
    width: 100%;
    padding-bottom: 30%;
    padding-top: 30%;
    border-radius: 6rem;
}


.image {
    text-align: center;
    vertical-align: middle;
    height: 100%;
}





.yellow {
    background-color: #F5D495;
}

.pink {
    background-color: #FFA2C0;
}

.blue {
    background-color: #7BB5D7;
}

.caption {
    color: white;
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}



@media only screen and (max-width: 786px){
    .Color_Card {
        width: 50%;
        padding: 15% 0;
        border-radius: 2.5rem;
    }

    .img {
        height: 48px;
    }

    .caption {
        vertical-align: middle;
        font-size: 1rem;
        height: 40%;
        margin: auto;
        padding: 0 2%;
    }
}