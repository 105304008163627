.Title_Card {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    background-color: #0D0F22;
    color: white;
    display: inline-block;
    padding: 1rem 2.5rem;
}

@media only screen and (max-width: 698px){
    .Title_Card{
        font-size: 0.75rem;
        padding: 0.5rem 1.5rem;
    }
}

.left {
    border-radius: 100rem 100rem 100rem 0rem;
}


.right {
    border-radius: 100rem 100rem 0rem 100rem;
}