.page_title{
    font-size: 0.75rem;
    color: var(--text-primary);
    margin-left: 4%;
    margin-bottom: 7%;
    margin-top: 7%;
}

.darken_text {
    color: var(--secondary)
}
.navbar{

    z-index: 4 !important;
}
.header_image {
    min-height: 100vh;
    background-size: cover;
    margin-top: -160px;
    z-index: 0 !important;
    /* padding-top: 10.2vh; */
    background-position: center;
}

.teamHead{
    font-size: 35px;
    color: var(--heading-color2);
    text-align: center;
    font-weight: 400;
}

@media (max-width: 998px) {
    .header_image {
        position: static;
        min-height: 56vh;
    }

    .teamHead{
        font-size: 20px;
    }
}


.text_box {
    margin: 0 9%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 1.5rem;
    min-height: 30%;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
    padding: 6% 5%;
    position: relative;
    top: -100px;
    z-index: 5;   
    background: #121528; 
}

@media (max-width: 998px) {
    .text_box {
        top: -60px;
    }
}


.heading {
    font-size: 4.5rem;
    color: var(--text-primary);
    font-weight: 200;
    text-align: center;
    padding: 0 20%;
}

@media (max-width: 998px) {
    .heading {
        font-size: 2rem;
        padding: 0;
    }
}



.caption {
    font-size: 1.25rem;
    color: var(--secondary);
    font-weight: 200;
    text-align: center;
}

@media (max-width: 998px) {
    .caption {
        font-size: 1rem;
    }
}


.gradient_text {
    font-weight: 600;
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#FC4668, #3F5EFB);    
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     margin: 0px;
}

.Values_title {
    margin-top: 10%;
    margin-bottom: 5%;
}

@media (max-width: 998px) {
    .Values_title {
        margin-left: 9%;
        margin-right: 9%;
        margin-bottom: 8%;
    }
}

.circularCard1 {
    margin-top: 10%;
    margin-left: 9%;
    margin-right: 9%;
    display: flex;
    justify-content: space-between;
}

.circularCard2 {
    margin-left: 9%;
    margin-right: 9%;
    display: flex;
    justify-content: space-around;
}

.para_btn {
   margin: 0 auto;
   text-align: center;
   margin-top: 3%;
}

.para {
    color:#7F89BE;
    text-align: center;
}

@media (max-width: 948px) {
    .para_btn {
        margin: 0 9%;
        text-align: left;
     } 
     
    .para {
        margin-top: 5%;
        margin-bottom: 2%;
        margin-left: 9%;
        margin-right: 9%;
        text-align: left;
    }
  
}

.circleImage {
    height: 160px;
}

.circle_container1{
    display: flex;
    margin-top: 5%;
}
.circle_container2{
    display: flex;
    flex-flow: row-reverse;
}

.circle_container3{
    display: flex;
}

.heading1 {
    display: flex;
    flex-direction: column;
  width: 100%;
    margin: 2vh auto;
    margin-bottom: 2rem;
}

.bold {
    font-weight: 600; 
}
.heading1_h {
    font-size: 5.3rem;
    color: var(--heading-color1); 
     width: 50%;
     margin: 0 auto;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 100;
    margin-bottom: 1%;
    margin-top: 4vh ;
}
@media only screen and (min-width: 779px) and (max-width: 1188px){
   
    .heading1_h{
        font-size: 3.3rem;
    }
    .para {
        margin-top: 5%;
        margin-bottom: 2%;
        margin-left: 13%;
        margin-right: 13%;
        text-align: left;
    }
    .para_btn {
        margin: 0 13%;
        text-align: left;
     } 
     .circleImage {
        height: 280px;
    }
  }
  @media only screen and (min-width: 679px) and (max-width: 848px){
    .circleImage {
        height: 210px;
    }

}
.searchContainer{
    width: 70%;
    justify-content: center;
    align-content: center;
    margin: 4rem auto;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    background:  var(--backgroud-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.seachBox{
    width: 100%;
    height: 4rem;
    background:  var(--backgroud-color);
    border:none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    padding-left: 1.5rem;
}
.seachBox:focus , .seachBox:active , .seachBox:focus-visible{
    outline: none;
}
.searchBtn{
    width: 6rem;
    background:  var(--button-gradient1);
    border: none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

}

@media (max-width: 798px) {
    
    .heading1_h{
        font-size: 3.3rem;
    }
}