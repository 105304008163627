.Hashtag_Container {
    margin-top: 10%;
    display: flex;
    font-size: 0.8rem;
    margin-left: 0.5%;
    margin-right: 9%;
    flex-flow: row wrap;
    overflow-x: scroll;
}

.shiftright {
    margin-right: 1.5rem;
    margin-bottom: 0.6rem;
}

@media (max-width: 1098px) {
    .Hashtag_Container {
        flex-wrap: nowrap;
    }
}