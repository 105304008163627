.container {
    margin: 0 9%;
}

@media (max-width: 768px) {
    .container {
        margin: 0 0 0 0;
    }
}

.select_departments {
    display: flex;
    background: #0D0F22;
    border-radius: 100px;
    padding: 1rem;
    overflow-x: scroll;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .select_departments {
        border-radius: 100px;
        margin-right: 0;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        padding: 0.6rem;
    }
}


.departments_container {
    flex: 1;
    display: flex;
    overflow: auto;
}

@media (max-width: 768px) {
    .departments_container {
        margin-left: 4%;
    }
}

.department {
    color: white;
    border-radius: 100px;
    transition: 0.6s;
    /* min-width: 180px; */
    padding: 0 1rem;
    margin: 0 0.3rem;
    text-align: center;
}

.department:hover{
    color: white;
    background: #4A517E; 
    cursor: pointer;
}
.department_select{
    color: white;
    border-radius: 100px;
    transition: 0.3s;
    padding: 0 1rem;
    margin: 0 0.3rem;
    background: #4A517E; 
    cursor: pointer;
}

.department p {
    padding: auto 1rem;
}

@media only screen and (min-width: 679px) and (max-width: 848px){

}
@media (max-width: 768px) {

}
.team_members {
    display: flex;
    justify-content: space-around;
    min-height: 60%;
    margin-top: 10%;
    transition: 1s;
    flex-wrap: wrap;
}