.mainContainer {
  width: 62%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  padding: 0.2rem 0.0rem 1rem 2rem;
  z-index: 0;
  scrollbar-width: none;
  background:  var(--backgroud-color);
}

.bredcrumb {
  display: flex;
  color: var(--secondary);
  padding-left: 1.2%;
  cursor: pointer;
  font-family: var(font-family);
}

.bredcrumb h3 {
  /* font-size: 1.5rem; */
  letter-spacing: 0.3px;
  font-weight: 300;
  font-size: 1.3rem;  
}

.selectBread {
  color: var(--heading-color2);
  font-weight: 700;
}
.notSelected{
  font-weight: 700;
  
}
.statusDisplay {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  flex: 1; 
  overflow-y: auto;
  justify-content: flex-start;

}

@media only screen and (max-width: 600px) {
  .mainContainer {
    padding: 0;
    width: 100%;
  }

  .bredcrumb {
    padding: 0;
    padding-left: 5%;
  }
  .bredcrumb h3 {
    padding-left: 0.4rem;
    margin-top:0.9rem ;
  }
  .statusDisplay {
    flex-direction: column;
    align-items: center;
    /* justify-content: center;  */
    padding-left: 10%;
    margin-top: 3.5rem;
  }
}
@media only screen and (min-width: 789px) and (max-width: 1288px){
  .mainContainer {
  
    padding: 0.2rem 0.0rem 1rem 0.4rem;
  }
  
}