.Small_Blog_Card {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    font-family: 'Poppins', sans-serif;
    background-color: #0D0F22;
    box-shadow: 0px 3rem 10rem rgba(0, 0, 0, 0.3);    
    border-radius: 2rem;
    min-width: 100px;
}


@media (max-width: 768px) {
    .Small_Blog_Card {
        max-width: 100%;
        margin-bottom: 10%;
    }
}
.image_box {
    width: 100%;
    min-height: 36%;
}
.image_box img {
    width: 100%;
    height: 100%;
}

.text_box {
    padding: 2rem 2rem ;
}

.hashtag_datebox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color:#4A517E;
}

.hashtag {
    color: transparent;
    background-clip: text;
    padding-right: 10px;
    background: -webkit-linear-gradient(left,#00C6FF, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;

}

.date {
    color: #4A517E;
}


.title {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
}

.caption {
    color: #7F89BE;
    font-size: 0.8rem;
    margin-bottom: 3.5rem;
    font-weight: 400;
}

@media (max-width: 768px) {
    .title {
        font-size: 1.25rem;
    }
    
    .caption {
        font-size: 1rem;
    }
}

.button_box {
    display: flex;
    justify-content: space-evenly;
}


.image{
    border-radius: 2rem 2rem 0rem 0rem;
}
