.color_card_block {
    display: flex;
    justify-content: space-around;
    margin-top: 7%;
}

@media only screen and (max-width: 786px){
    .color_card_block {
        flex-direction: column;
    }
}