.Violet_Border_Button {
    border-radius: 100rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: white;
    padding: 0.75rem 1.75rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, #FC466B 0%, #3F5EFB 100%);
    background-origin: border-box;
    --webkit-background-origin: border-box;
    background-clip: content-box, border-box;
    --webkit-background-clip: text;
    box-shadow: 2px 1000px 1px#0D0F22 inset; 
    padding-left: 20%;
    padding-right: 20%;
    cursor: pointer;
}


.button_text {
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#FC466B, #3F5EFB); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}