.mainContainer {
    width: 80%;
     display: flex;
    flex-direction: column; z-index: 0;
    margin-bottom: 0.3rem;
    background:  var(--backgroud-color);
  }
  

  .exploreDisply{
    padding: 0.2rem 1.9rem 3.8rem 1.8rem;
    overflow-y: auto;
  }

  .bredcrumb {
    display: flex;
    color: var(--secondary);
    cursor: pointer;
    font-family: var(font-family);
  }
  
  .bredcrumb h3 {
    /* font-size: 1.5rem; */
    letter-spacing: 0.3px;
    font-weight: 300;
    font-size: 1.3rem;  
  }
  .selectBread1 {
    color: var(--heading-color2);
    font-weight: 700;
    padding-left: 1.9rem;
  }
  .selectBread {
    color: var(--heading-color2);
    font-weight: 700;
  }
  .notSelected{
    font-weight: 700;
    
  }
  .statusDisplay {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    justify-content: flex-start;
  
  }
  .statusDisply{
    margin-top: -0.6rem;
  }
  .grpContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    align-content: center;
    margin-top: 1.8rem;
    margin-left: 0.6rem;
    padding-left: 0.6rem;
    margin-bottom: 0.9rem;
    width: 20.5%;
  }
  .grpname{
  width: 77%;
  font-family: var(font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.1rem;
  /* identical to box height */
  color: var(--heading-color2);
  margin: 0 2.6rem 0 0.6rem;
  }
  .viewAll{
    background: -webkit-linear-gradient(360deg, #FC466B, #3F5EFB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.85rem;
    letter-spacing: 0.05em;
    
   }
  /* #FC466B
100%
#3F5EFB
100% */
  @media only screen and (max-width: 600px) {
    .mainContainer {
      padding: 0;
      width: 100%;
    }
  
    .bredcrumb {
      padding: 0;
      padding-left: 5%;
    }
    .bredcrumb h3 {
      padding-left: 0.4rem;
      margin-top:0.9rem ;
    }
    .statusDisplay {
      flex-direction: column;
      align-items: center;
      /* justify-content: center;  */
      padding-left: 10%;
      margin-top: 3.5rem;
    }
  }
  