.Transparent_White_Button{
    border-radius: 100px;
    padding: 0.75rem 1.75rem;
    font-family: 'Poppins', sans-serif;
    background: transparent;
    color: white;
    border: 1px solid white;
    font-size: 1rem;
    cursor: pointer;
}

@media only screen and (min-width: 889px) and (max-width: 920px) {
    .Transparent_White_Button{
        padding: 0.65rem 1.05rem;
    }
}