.container {
  display: flex;
  height: 5rem;
  margin-right: 1rem;
  
  color: var(--text-primary);
}

.container:before {
  content: "\200B";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.navbarContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 80rem;
  max-height: 5rem;
  border-top-left-radius: 30px;
  display: flex;
  padding-top: 1.1rem;
  padding-bottom: 0.4rem; 
  background: var(--backgroud-color);
  z-index: 4;
  position: relative;
}

.left {
  float: left;
  display: flex;
  flex: 1;
  margin:auto 0;
}

.title {
   display: flex;
 /* font-size: 2rem; */
  font-family: var(font-family);
  height: 100%;
  width: 18%;
  padding: 0.75rem;
margin-top: -0.95rem;
  /* align-items: center; */
  color: #ffffff;
  cursor: pointer;
}

.title img {
  width: 7rem;
  height: 100%;
}

.links {
  /* margin-top: 0.3rem; */
  margin-left: 5.5rem;
  padding-left: 2.2rem;
  display: inline;
}
@media only screen and (min-width: 789px) and (max-width: 1188px){
  .links {
    padding-left: 0;
    margin-left: 0.5rem;
  }
}
.links span{
  font-size: 0.95rem;
  /* color: var(--secondary); */
}
.iconsText{
  margin-top: -0.8rem !important;
  margin-bottom: 0.4rem;
}
.links span img{
  margin-bottom: -0.2rem;
 
  margin-right: 0.3rem;
}
.icons{
  margin-bottom: -0.3rem !important;
  margin-right: 0.3rem; display: inline;
}
.right {
  margin-top: -0.6rem;
  margin-right: 1rem;
  width: 40%;
  flex: 0;
  display: flex;
  justify-content: flex-end;
  float: right;
}

.right a:hover {
  text-decoration: none;
}

.search {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: var(font-family);
  align-items: center;
  margin-right: 1rem;
}

.search img{
  margin-right: 1.4rem;
}
.user img{
  margin-left: 0.5rem;
  margin-right: 0.3rem;
}
.user {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--heading-color2) !important;
  width: 9.5rem !important;
}
.username{
  color: var(--heading-color2) !important;
}
@media only screen and (min-width: 1200px) {
  .left {
    padding-left: 0.7em;
  }
  .right {
    margin-left: -1.5%;
  }
  .user {
    margin-left: 1%;
    width: 100%;
  }
}

.notification {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-right: 0.5rem;
  /* border-left: 1px solid #eee; */
  /* border-right: 1px solid #eee;*/
}
.notification a {
  display: flex;
  align-items: center;
}
.notificationIcon {
  display: flex;
  align-items: center;
  margin-top: -0.1em;
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  position: absolute;
  top: 1rem;
  left: 1.9rem;
  background: #ff5b5b;
  border-radius: 50%;
}

.username {
  margin: 0;
  padding-left: 0.3rem;
  /* font-size: 16px;
  color: #555; */
  /* font-family: Avenir Next; */
  letter-spacing: 0.3px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  /* identical to box height */

  color: var(--secondary);
}

.userMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputField {
  margin-top: 0.5rem;
}
@media only screen and (max-width: 700px) {
  .title {
    font-size: 1.5rem;
    width: 5.5rem;
    padding: 1rem 0.75rem;
  }

  .links {
    display: none;
  }

  .notification {
    color: #555;
    border: none;
    padding: 10px;
  }
  .search {
    padding: 10px;
  }

  .searchText {
    display: none;
  }

  .locationContainer {
    border: 0;
  }

  .username {
    display: none;
  }
}
