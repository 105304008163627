.Big_Blog_Card {
    margin: 8%;
    display: flex;
    font-family: 'Poppins', sans-serif;
    background-color: #0D0F22;
    box-shadow: 0px 3rem 10rem rgba(0, 0, 0, 0.3);    
    border-radius: 2rem;
    max-height: 600px;
}

.image_box {
    max-width: 50%;
    min-width: 48%;
    max-height: 100%;
    border-radius: 2rem 0 0 2rem;
}


.text_box {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
}

.hashtag {
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#00C6FF, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
    color: white;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.caption {
    color: #7F89BE;
    font-size: 1rem;
    font-weight: 400;
}

.date_box {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.date {
    color: #4A517E;
}


@media (max-width: 768px) {
    .Big_Blog_Card {
        display: none;
    }
}
@media only screen and (min-width: 709px) and (max-width: 1208px){
    .Big_Blog_Card {
        max-height: 60vh;
    }
    .text_box {
        padding: 2%;
    }
    .title {
        color: white;
        font-size: 1.8rem;
    }
    .caption{
        font-size: 0.9rem;
    }
    .date_box{
        margin-top: 1rem;
        margin-bottom: 1rem;
        width:90%;
    }
    .date {
        color: #4A517E;
        text-align: right;
    }
}