.container {
    width: 78%;
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.0rem 0.8rem 1rem;
    position: relative;
    z-index: 1;
    margin-bottom: 0.2rem;
  scrollbar-width: none;
  background:  #121528;
    font-family: Roboto;
  }
  .title {
      border-top:1px solid #2E2E48;
      border-bottom:1px solid #2E2E48;
    position: relative;
    padding: 0.5rem 1rem;
    /* width: max-content; */
    background-color:var(--background-color);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    width: 94%;
    align-items: center;
    margin: 1.05rem 1rem;
    cursor: pointer;
  }

  .content {
    font-size: 0.9rem;
    margin: 0;
    margin-left: 0.5rem;
    flex: 1;
  }

  .contentHead{
    font-size: 1.2rem;
    margin: 0;
    margin-left: 0.7rem;
    flex: 1;
  }
  .mainChatBox::-webkit-scrollbar {
    /* width: 0;
    background-color: transparent; */
  }

  .message {
    width: 94%;
    padding: 0.7rem 1rem;
    border: 0;
    height: 3rem;
    resize: none;
    border-radius: 6px;
    background-color: white;
    color: #555;
    box-shadow: 0px 2px 20px #00000026;
  }
  .rightTitle{
      margin-left: auto;
  }

.mainChatBox{
    display: flex;
    flex-direction: column;
    width:100%;
}
  .chat {
    display: flex;
    flex-direction: column;
    padding: 0.15rem 0.6rem 0.15rem 1.5rem;
    overflow-y: auto;
    margin-right: 0.6rem;
    height: 62.5vh;
  }

  .chat::-webkit-scrollbar{
      /* display: none; */
  }
  .myChat {
    margin: 0 0 0.3rem 0;
    display: flex;
    flex-direction: column;
  }
  .chatContent {
    align-items: flex-end;
    max-width: 60%;
    display: flex;
    justify-content: flex-start;
  }
  .messageCont{
    color: var(--heading-color2);
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
  }
  .time{
      font-size: 0.6rem;
      color: #7F89BE;
  }
  .timer{
    font-size: 0.6rem;
    margin-right: auto;
    color: #D9DEF4;
}
.chatUser{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.6rem;
    align-items: center;
}
.chatUser img{
    border-radius: 50%;
}
  .leftChatContent{
    /* align-items: flex-end;*/
    background: #0D0F22;
    border-radius: 0px 32px 32px 32px;
    padding:1rem;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.6rem 0;
  }
  .repeatleftChatContent{
    /* align-items: flex-end;*/
    background: #0D0F22;
    border-radius: 0px 32px 32px 32px;
    padding:1rem;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .myChatContent1{
    margin-left: auto;
    /* max-width: 50%; */
    padding:1rem;
    flex-direction: column;
    display: flex;

    align-items: flex-end;
    background: #4A517E;
    border-radius: 32px 0px 32px 32px;
  }
  .messageContRight{
      margin-right: auto;
      margin-top: -0.3rem;
      color: var(--heading-color2);
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
  }
.inputBox {
    border-top:1px solid #2E2E48;
  position: relative;
  padding: 0.6rem 1rem;
    display: flex;
    width: 100%;
    height: 5vh;
    margin-right: 2rem;
    background: #121528;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    flex-direction: row;
  }
  .inputBox img{
    width: 1.6rem;
    height: 1.6rem;
    margin: auto 0;
  }
  .inputMsg{
    background: #121528;
    border: 1px solid #7F89BE;
    box-sizing: border-box;
    border-radius: 100px;
    outline: none;
    margin: 0.1rem 0.9rem;
    padding: 0.5rem;
    padding-left: 0.6rem;
    width:71%;
    color: #d9def4;
  }
  .sendBtn{
    background: linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%);
    box-shadow: 0px 4px 15px rgba(189, 78, 156, 0.2);
    border-radius: 90px;
    color: var(--heading-color2);
    padding: 0 2rem;
    border : 1px solid #121528;
    margin-right: 0.5rem;
  }
   @media only screen and (min-width: 789px) and (max-width: 1188px){
    .chat {
      height: 58vh;
    }
    .inputMsg{
      width:65%;
    }
  }
