.character_box_container {
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif; 
    z-index: 5;
}

.character_container {
    display: flex;
    justify-content: space-between;
    
}

.text_box {
    padding: 2rem;
    font-size: 1.5rem;
    color: #7F89BE;
    background-color: #0D0F22;
    border-radius: 2rem;
    width: 30%;
    top: -47px;
    position: relative;
    z-index: 10;
}

.image {
    width: 33%;
}

.top_text_row {
    top: 40px;
}

.bottom_text_row {
    top: -800px;
}

.top_image1_row {
    position: relative;
    top: 100px;
}

.top_image2_row {
    position: relative;
    top: 100px;
}

.botttom_image1_row {
    position: relative;
    top: -750px;
    z-index: 3;
}

.bottom_image2_row {
    position: relative;
    top: -745px;
    left: 50px;
    z-index: 3;
}






.heading1_h {
    font-size: 4rem;
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    position: relative;
    top: -700px;
    padding-bottom: 5%;
    z-index: 10;
    background-color: #121528;
}



.bold {
    font-weight: 600; 
}


.red_border {
    border: 1px solid #E42A5B;
}

.green_border {
    border: 1px solid #BDF2A5;
}

.yellow_border {
    border: 1px solid #F5D495;
}

.blue_border {
    border: 1px solid #00C6FF;
}



.background_box_container {
    display: flex;
    justify-content: center;
    min-height: 800px;
}

.background_box{
   
    width: 35%;
    /* border: 1px solid #7F89BE; */
    clear:both;
    z-index: 1;
    border-radius: 2rem;
    margin: 0 3%;
}

.Character_Text_Grid {
    margin: 0 auto;
}

.yellow_character {
    position: relative;
    top: 30px;
    left: 28px;
}
@media only screen and (min-width: 789px) and (max-width: 1188px){
   
    .heading1_h , .heading2_h , .heading3_h, .heading4_h {
        font-size: 3.3rem;
    }
  }