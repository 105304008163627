
.wholeContainer{
  display: flex;
  flex-direction: column;
  width: 86%;
  height: 94vh;
  max-width: 1300px;
  padding-left: 0.4rem;
  /* padding-top: 0.5rem; */
  max-height: 1000px;
  overflow-y: hidden;
  margin: 1rem auto;
   border-radius: 20px;
   z-index: 5 !important;
  background:  var(--backgroud-color) !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.dashboardToggler {
 margin-left: 80%;
 display: flex;
}

.toggler {
  margin-right: 20px;
  cursor: pointer;
}
.sidebarContainer {
  display: flex;
  flex-direction: column;
  width: 20.5%;
  background:  var(--backgroud-color);
  font-family:var(font-family);
  padding-left: 0.6rem;
}
.sidebarContainer::-webkit-scrollbar{
  display: none;
}
.grpContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  align-content: center;
  margin-top: 1.8rem;
  margin-left: 0.6rem;
  margin-bottom: 0.9rem;
  width: 105%;
}
.grpname{
width: 37%;
font-family: var(font-family);
font-style: normal;
font-weight: 400;
font-size: 0.9rem;
line-height: 1.1rem;
/* identical to box height */

color: var(--heading-color2);
margin: 0 2.9rem 0 0.6rem;
}
.hide{
  display: none;
}
@media only screen and (min-width: 1200px) {
  .homeContainer {
  padding-left: 0.7em;
  }
}

.homeContainer:before {
  content: "\200B";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.center {
  /* margin: 0 auto; */
  padding: 0;
  width: 100%;
  height:94%;
  display: flex;
  background:  var(--backgroud-color);
  overflow: hidden;
  z-index: 4 !important;
  border-bottom-right-radius: 32px;
  position: relative;
  
}

.noStatusContainer {
  width: 67% !important;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .center {
    height: 100%;
  }
}
@media only screen and (min-width: 905px) and (max-width: 1244px){
  .wholeContainer{
    width: 97%;
    height: 94vh;
    padding-left: 0.1rem;
  }
  .sidebarContainer {
    width: 22.5%;
    padding-left: 0.2rem;
  }
}