.container {
    padding: 0 8% 0 8%;
    padding-top: 1rem;
    width: 87%;
    z-index: 20;
}

.scrolled {
    position: fixed;
    top: 0;
    background: var(--backgroud-color);
}

.scrolledNav {
    margin: 0.7% 0  !important;
}
.nav {
    margin-top: 5.1%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.no_margin {
    margin-top: 0%;
}

.nav a {
    margin-right: 2vw;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #7F89BE;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    font-size: 1rem;
}
.logo {
    margin-right: 5%;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #7F89BE;
    font-size: 1rem;
    cursor: pointer;
}

.navLogo{
    height: 50px;   
}

.button_box {
   display: flex;
   margin-left: 3rem;
   cursor: pointer;
}

.shift_left
{
    cursor: pointer;
    margin-left: 2rem;
}

@media only screen and (max-width: 699px) {
    .button_box {
        margin-left: auto;
        justify-content: end;
        margin-right: 0.4rem;
        cursor: pointer;
     }
     .container {
        margin: 0;
        padding-top: 1rem;
    }
   
  }
  @media only screen and (max-width: 879px) {
       .nav a {
        display: none;
    }
  }
  @media only screen and (min-width: 890px) and (max-width: 1210px) {
    .container {
        margin: 0 0.5% 0 1.5%;
        padding-top: 1rem;
    }
    .nav a {
        margin-right: 1vw;
    }
    .logo {
        margin-right: 2%;
    }
    
  }