.rightFaqWrapper{
    width: 99%;
    margin:0.8em 1rem;
    padding-left: 1%;
  }
  .boxes{
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.5);
      border-radius: 8px !important;
      border: none !important;
      
      margin:  1.2em 0;
  }
  .boxHead{
    padding-top:0.4em;
    padding-bottom: 0.4em;
    border: none;
  }
  .popoverHead {
    margin: 0;
    width: 100%;
    font-size: 18px;
    border: none;
    display: flex;
    justify-content: space-between;
    color: var(--secondary);
    background:  var(--backgroud-color);
  }
  .expansion-panel {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none !important;
  }
  .popoverBtn {
    outline: none;
    border: none;
    background:  var(--backgroud-color);
  }
  .boxbody{
    margin-top: -0.5em;
    font-size: 0.95rem;
    color: var(--heading-color2);
    background:  var(--backgroud-color);
    border: none;
    font-style: normal;
font-weight: 300;
  }
  
 .faqs{
  display: flex;
  flex-direction: row;
  background-color: var(--backgroud-color) ;
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  margin: 3rem 9vw;
  padding: 1.5rem 2rem;
}
.leftFaq{
  display: flex;
  flex-direction: column;
  width: 25%;
}
.rightFaq{
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
  margin-top: 0.5rem;
  width: 99%;
}
.rightFaq::-webkit-scrollbar{
  /* width: 20%; */
}
.sidebar {
  
  width: 100%;
  padding: 0 ;
  padding-left: 2%;
  padding-right: 1%;
  list-style: none;
  overflow: hidden;
  
}

.sidebar::-webkit-scrollbar {
  /* width: 0;
  background: transparent; */
}

.link {
  padding: 1rem 1.3rem;
  width: 100%;
  color: var(--secondary);
  opacity: 0.99;
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0.3rem 0;
}

.link:hover {
  color:  var(--text-primary);
  background: var(--button-gradient2);
  border-radius: 16px;
  cursor: pointer;
}

.selected {
  cursor: pointer;
  padding: 1rem 1.3rem;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0.3rem 0;
  color:  var(--text-primary);
  background: var(--button-gradient2);
  opacity: 1;
  border-radius: 16px;
}

  @media screen and (max-width: 868px) { 
    .rightFaqWrapper{
      width: 100%;
      margin:0.8em 0rem;
    }
    .faqs{
      display: flex;
      flex-direction: column;
      background-color: var(--backgroud-color) ;
      /* box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.5);
      border-radius: 24px;*/
      /* width: 94%; */
      margin: 3rem 1vw;
      padding: 1.5rem 0.5rem; 
    }
    .sidebar {
      width: 100%;
      padding: 0 ;
      padding-left: 2%;
      padding-right: 1%;
      list-style: none;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow-x: auto;
    }
    .leftFaq{
      display: flex;
      flex-direction: row;
      width: 100%;
      
    }
    .leftFaq::-webkit-scrollbar{
      display: none;
    }
    .rightFaq{
      height: 80vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
      padding-right: 0.3rem;
      width: 100%;
    }

    .link {
      padding: 1rem 1.2rem;
      width: 100%;
      opacity: 0.99;
      font-size: 1rem;
      box-sizing: border-box;
      margin: 0.3rem 0;
      color: #00C6FF;
      border:1px solid  #00C6FF;
      border-radius: 86px;
    }
    
    .link:hover {
      border-radius: 86px;
      cursor: pointer;
    }
    
    .selected {
      cursor: pointer;
      padding: 1rem 1.2rem;
      width: 100%;
      font-size: 1rem;
      box-sizing: border-box;
      margin: 0.3rem 0rem;
      opacity: 1;
      border-radius: 86px;
    }
    .links{
      margin:0 0.5rem;
      font-size: 1rem;
    }
  }