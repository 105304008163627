.navbar {
  padding: 0 0 .3rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  background: var(--backgroud-color);
  height: 69px;
}
.dashbar{
  padding: 0 1rem 0.3rem;
  margin-top: -40px;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 99%;
  background: var(--backgroud-color);
  height: 64px;
}
.btns {
  width: 45%;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
}
.btns:hover {
  color: #fff;
}
.txt{
  margin-top: 0.4rem;
}
.txt1{
  margin-top: 0.2rem;
  color: #00C6FF;
}
.circle {
  margin-top: -9px;
  padding: 1.2rem;
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.9;
  border: 2px transparent;
  background: linear-gradient(112.23deg, rgba(196, 196, 196, 0.17) 0%, #00C6FF 0.01%, #0072FF 100%);
  box-shadow: 0px 0px 15px rgba(108, 93, 211, 0.5);
}
.plus {
  color: #fff;
}
.none{
  display: none;
}
.popup{
  display: flex;
}
