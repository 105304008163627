.popover{
    padding: 0;
    width: 100%;
    background-color: var(--backgroud);
    overflow: hidden;
}

.btns{
    padding: 3rem 0;
}

.btn{
    padding: 1rem 2rem;
}

.selected{
    color: var(--heading-color2);
    text-decoration: none;
}

.link{
    text-decoration: none;
    color: var(--text-dark);
}