.addContainer{
  display: flex;
  flex-direction: column;
  /* height: 80.5%; */
  width: 20%;
  /* background:  var(--backgroud-color); */
  /* z-index: 4 !important;
  border-bottom-right-radius: 32px; */
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); */
  opacity: 6;
  overflow: hidden;
  overflow-x: hidden;
  margin-top: 2.8rem;
}
@media screen and (min-width: 1400px)  and (max-width: 1600px) {

}
.statusList {
  display: flex;
  flex-direction: column;
  height: fit-content;
  
  overflow-y: auto;
}
.trending{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchBox {
  padding: 0.35rem 0.4rem;
  bottom: 0px;
  left: 1;
  justify-content: end;
  align-items: center;
  position: absolute;
  display: flex;
  width: 68%;
  border: 1px solid var(--secondary);
  border-radius: 8px;
}


.searchField {
  flex: 1;
  border: none;
  z-index: 0;
  width: 50% !important;
  position: relative;
  border-radius: 4px;
  font-size: 13px;
  padding:0.2rem ;
  padding-left: 0.5rem;
  background:  var(--backgroud-color);
}
input::placeholder{
  color: var(--secondary);
}

.statusList::-webkit-scrollbar {
  width: 0;
}

.addStatusContainer {
  display: flex;
  width: 100%;
  padding: 1rem 0 2.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
}
.thoughts{
  margin: 1rem;
  display: flex;
  flex: 1;
  text-align: right;
}
@media only screen and (min-width: 789px) and (max-width: 1188px){
  .thoughts{
    text-align: left;
    margin-left: 0.4rem;
    margin-right: 0.1rem;
  }
}
.thoughtsHead{
  color: var(--heading-color1);
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 0.4rem;
}
.feednames{
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0.125rem;
  padding: 0.5rem 0.125rem;
}
.tag {
  font-family: var(font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--secondary);
  padding-left: 0.5em;
  margin-bottom: 0;
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.activetag {
  margin: 0;
  color: var(--heading-color2);
  font-family: var(font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
  padding-left: 0.5em;
  margin-bottom: 0;
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.tagContainer{
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: space-between;  
} 
.activetagContainer{
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 768px) {
  .sidebarContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .addStatusContainer {
    display: none;
  }
}
