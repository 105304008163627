.Testimonial_Carousel{
    display: flex;
    justify-content: space-around;
    width: 100%;
    overflow-x: scroll;
    flex-direction: initial;
    scroll-behavior: smooth;
}

.Testimonial_Carousel::-webkit-scrollbar {
    display: none;
}
  
.Image_Box {
    min-width: 100%;
    max-width: 100%;
    max-height: 40%;
    margin-right: 3.33%;   
}
.image{
    margin-left: 1%;
    width: 30%;
    height: 30%;
}

.ScrollButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.right_arrow {
    margin-right: 4%;
}