.image_row {
    display: flex;
    padding: 0 4rem;
    justify-content: space-between;
    margin-bottom: 3%;
    z-index: 5;
}

@media only screen and (max-width: 786px){
    .image_row {
        padding: 0 2rem;
    }
}



.title_row {
    display: flex;
    justify-content: space-between;
}

.padding {
    padding: 0 15%;
}