
.dots {
    display: flex !important;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 50%;
}

.dotsBtn{
    width: 1rem;
    height: 1rem;
    background: transparent !important;
    border-radius: 50%;
    border: 1px solid #7F89BE;
}

.dots li {
    margin: 1rem;

}

.dots li.slick-active button:before {
    color:White !important;
}

.active{
    width: 1rem;
    height: 1rem;
    background: transparent !important;
    border-radius: 50%;
    border: 1px solid #D9DEF4;
}

/* .rotate_left {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin:    0 0;
    -ms-transform-origin:     0 0;
    transform-origin:         0 0;
    
    -webkit-transform: rotate(5deg);
    -moz-transform:    rotate(5deg);
    -ms-transform:     rotate(5deg);
    transform:         rotate(5deg);
} */