// SASS variable for media query breakpoint
$breakpoint-desktop: 786px;

// Resetting default styles
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

// Carousel slides
.carousel__slide {
  width: 100%;
  display: none;

  list-style-type: none;
  text-align: center;
  font-weight: 200;
  margin: 0;
  width: 100%;

 

  &--active {
    display: block;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
}

// Content of slides
.carousel-slide__content {
  margin-bottom: 19px;
  font-family: 'Poppins', sans-serif;
  font-size: 4.5rem;
  color: white;
  font-weight: 200;

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: left;
  }
  @media only screen and (min-width: 789px) and (max-width: 1188px){
    font-size: 3rem;
  }
}

.carousel-slide__author,
.carousel-slide__source {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #7F89BE;
  font-weight: 300;
  

  @media (max-width: 768px) {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
  }
  @media only screen and (min-width: 789px) and (max-width: 1188px){
    font-size: 1.2rem;
  }
}

.carousel-slide__source {
  font-style: italic;
  color: #888;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .5;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 20px;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
}

.carousel__indicator {
  display: block;
  width: 0px;
  height: 3px;
  background-color: transparent;
  cursor: pointer;
  opacity: .15;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  padding: 1rem;

  &:hover {
    opacity: .5;
  }

  &--active {
    &,
    &:hover {
      opacity: .75;
    }
  }

  @media (max-width: 768px) {
    padding: 5px;
  }
}


@media (max-width: 768px) {
  .indicator_image{
    height: 10px;
  }
}
