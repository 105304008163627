.container {
  margin: 0 auto;
  padding: 5rem 7rem 0.1rem 7rem;
  /* width: 100vw; */
  justify-content: center;
  align-items: baseline;
  font-family: var(font-family);
  color: var(--secondary);
  background: var(--footer-background);
}

.cont {
  display: flex;
}

.info {
  margin-top: -2rem;
  width: 40%;
}

.brand {
  font-size: 4rem;
  color: var(--text);
  max-width: 12rem;
}

.brand img {
  width: 100%;
}

.options,
.options1 {
  display: flex;
  width: 60%;
  flex-direction: row-reverse;
}

.class {
  font-size: 1.2rem;
}

.option {
  margin: 0 2rem;
  max-width: 30%;
}

.links {
  margin: 0 0 1rem 0.1rem;
  list-style: none;
  font-size: 1rem;
  color: var(--heading-color2);
}

.links > a {
  color: var(--heading-color2);
  text-decoration: none;
}

.links > a:hover,
.links:hover {
  color: var(--text-purple);
}

.footer {
  margin: 0 auto;
  padding: 4rem 7rem;
  /* width: 100%; */
  position: relative;
  display: flex;
  align-items: baseline;
  color: var(--heading-color2);
  background: var(--footer-background);
}

.copy {
  margin-top: auto;
  width: 64%;
  padding-bottom: 1rem;
  text-align: right;
  margin-right: 2rem;
  font-size: 0.8rem;
  color: var(--text-dark);
}
.head2 {
  font-size: 1.3rem;
  color: var(--text-dark);
}
.icons {
  width: 44%;
  display: flex;
  justify-content: space-between;
}
.iconsLeft {
  color: var(--text-primary);
  margin: 0.5rem 0;
  font-style: normal;
  font-weight: 200;
  font-size: 1.1rem;
  text-decoration: none;
}
.iconsLeft img {
  margin-top: 0.2rem;
}
.iconsLeft span {
  margin-top: -0.4rem;
  margin-left: 0.2rem;
}
@media screen and (max-width: 900px) {
  .container {
    padding: 5rem 0 0 0;
    margin: 0 0;
    /* width: 99%; */
  }
  .cont {
    display: flex;
    flex-direction: column;
  }

  .info {
    padding: 0 2rem;
    width: 75%;
  }

  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .options1 {
    width: 100%;
    display: flex;
    margin-left: 0;
    padding-left: 0;
    flex-direction: column;
  }

  .option {
    max-width: 100%;
  }

  .links {
    width: 100%;
    margin: 1rem 0 1rem -2rem;
    font-weight: 300;
  }

  .links > a {
    color: var(--text-primary);
    text-decoration: none;
    font-weight: 300;
  }

  .footer {
    padding: 2rem 0 0 2rem;
    /* width: 91.5%; */
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: stretch;
    margin: 0 0;
  }

  .icons {
    padding: 1rem;
    width: 60%;
    display: flex;
  }

  .copy {
    padding: 1.5rem 0 2.5rem 0.4rem;
    font-size: 1rem;
    margin: 0rem 0rem;
    text-align: left;
  }

  .class {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}
@media only screen and (max-width: 920px) {
  .links {
    margin: 0 0 1rem 0.4rem !important;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1180px) {
  .icons {
    width: 80%;
  }
}
@media only screen and (min-width: 760px) and (max-width: 900px) {
  .icons {
    width: 36%;
  }
}
