.container {
    width: 80%;
    margin: 5% auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.centered {
    width: 80%;
    margin: 0 auto;
}

.title h2 {
    font-size: 3.5rem;
    color: white;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}

.authorImage{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
@media (max-width: 768px) {

    .centered {
        width: 100%;
        margin: 0 auto;
    }

    .title h2 {
        font-size: 2rem;
    }
}
@media (max-width: 998px) {

    .centered {
        width: 100%;
        margin: 0 auto;
    }
}

.details {
    display: flex;
    color: #7F89BE;
    justify-content: space-between;
    margin-bottom: 5%;
} 

@media (max-width: 768px) {
    .details {
        flex-direction: column;
    }
}


.blog_details {
    display: flex;
    min-width: 80%;
}

@media (max-width: 768px) {
    .blog_details {
        justify-content: space-between;
    }
}


.date {
    margin-right: 7%;
    font-size: 1rem;
}

.date_time {
    display: flex;
    width: 70%;
}
.author_box {
    display: flex;
    justify-content: space-around;
    margin-right: 7%;
}

@media (max-width: 768px) {
    .author_box {
        justify-content: flex-start
    }
}

.author {
    margin-left: 5px;
    font-size: 1rem;
}

.read_time {
    font-size: 1rem;
}


@media (max-width: 768px) {

    .centered {
        width: 100%;
        margin: 0 auto;
    }

    .title h2 {
        font-size: 2rem;
    }

    .caption {
        font-size: 1.25rem;
    }
}

.caption {
    font-size: 1.5rem;
    color: var(--secondary);
    margin-bottom: 10%;
    font-weight: 300;
}

.paraHead {
    font-size: 2.5rem;
    color: var(--heading-color2);
    font-family: 'Poppins', sans-serif;
    margin-top: 5rem;
    font-weight: 500;
}

.paraText {
    font-size: 1.25rem;
    color: var(--secondary);
    font-family: 'Poppins', sans-serif;
    font-size: 1.7rem;
    margin-top: 3rem;
    font-weight: 300;
    line-height: 2.5rem;
}

@media (max-width: 768px) {

    .paraHead{
       font-size: 1.25rem;
    }

    .paraText {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.8rem;
    }
}

.img_box {
    margin: 0 auto;
}

.img {
    width: 100%;
}

.shareIcon {
    display: flex;
    justify-content: right;
    margin-left: 10rem;
}

@media (max-width: 768px) {
    .shareIcon {
       margin-left: 0;
    }
}


.blog_stats_box {
    display: flex;
    color: #7F89BE;
    justify-content: space-between;
    margin-bottom: 5%;
}

.blog_stats {
    display: flex;
    min-width: 80%;
} 

.stat {
    margin-left: 5px;
}

.bold {
    font-weight: 500;
}

.sugestions_head{
    font-size: 3rem;
    font-weight: 200;
    margin: 0 9%;
    color: var(--text-primary);
    margin-bottom: 10%;
}

@media (max-width: 768px) {
    .sugestions_head{
        font-size: 2rem;
    }
}


.sugestions {
    display: flex;
    overflow: scroll;
    margin: 0 9%;
}

.sugestion_card {
    min-width: 90%;
    margin-right: 5%;
}

@media (max-width: 400px) {
    .sugestion_card {
        min-width: 100%;
    }
}
