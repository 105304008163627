.image_slide {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Poppins", sans-serif;
  transition-duration: 1s;
  border-radius: 1rem;
  width: 260px;
  background: #0d0f22;
  margin: 0 1%;
  margin-bottom: 30%;
}

@media (max-width: 768px) {
  .image_slide {
    width: 148px;
    border-radius: 1rem;
  }
}

.image {
  min-width: 100%;
  min-height: 80%;
  background-repeat: no-repeat;
  padding-top: 40%;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  transition-duration: 1s;
  border-radius: 2rem 2rem 0 0;
}

@media (max-width: 768px) {
  .image {
    border-radius: 1rem 1rem 0 0;
  }
}

.name {
  font-size: 1.5rem;
  color: white;
  transition-duration: 1s;
  font-weight: 500;
  margin-bottom: 0;
  background: #0d0f22;
  margin: 0;
  padding: 4% 0 3% 0;
}

@media (max-width: 768px) {
  .name {
    font-size: 1rem;
    padding: 4% 0 0% 2%;
  }
}

.quote {
  font-size: 1rem;
  color: #7f89be;
  transition-duration: 1s;
  background: #0d0f22;
  margin: 0;
  padding: 2% 0 6% 0;
  border-radius: 0 0 2rem 2rem;
}

@media (max-width: 768px) {
  .quote {
    font-size: 0.5rem;
  }
}
