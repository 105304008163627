.container {
    width: 100%;
    min-height: 100vh;
    background: #121528;
    display: flex;
    justify-content: space-around;
    max-height: 1000px;
    color: rgb(117, 106, 106);
    text-align: center;
}

@media (min-height: 1500px) {

    .container {
        min-height: 1000px;
    }
}


.row5 {
    width: 80%;
    min-height: 100%;
    display: flex;
    justify-content: space-around;
    height: 100%;
    margin: auto auto;
}

.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title{
    font-weight: 200;
    font-size: 3.5rem;
    margin-bottom: 1%;
}

.caption{
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--secondary);
}

.gradient_text {
    font-weight: 600;
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#8b0ae0, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}




@media (max-width: 868px) {
    .container {
        width: 100%;
        min-height: 50vh;
    }
    .row5 {
        width: 100%;
        
        margin: auto 0;
    }

    .title {
        font-size: 2rem;
        font-weight: 500;
        text-align: left;
    }

    .caption {
        font-size: 1rem;
        text-align: left;
    }

    
    .textbox {
       margin: 0 9%;
    }
}