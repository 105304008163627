.container {
    width: 50%;
    margin: 10% auto;
}


.header {
    margin-bottom: 10%;
}

.heading{
    font-size: 56px;
    font-weight: 200;
    color: var(--text-primary);
}

.bold {
    font-weight: 600;
}

.caption {
    color: var(--secondary);
    font-weight: 300;
    font-size: 24px;
    margin-top: 10px;
}

.para {
    margin-bottom: 10%;
}

.paraTitle{
    color: var(--heading-color2);
    font-weight: 500;
    font-size: 40px;
}

.paraContent {
    font-weight: 300;
    color: var(--secondary);
    font-size: 20px;
}


