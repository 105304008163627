
.text_carousel {
    margin: 0 9%;
    margin-top: 20%;
}

.Character_Text_Grid {
    margin: 0 9%;
    margin-top: 20%;
}

.title_button_block{
    margin: 8% auto;
}

.feature_scroll_cards {
    margin: 0 9%;
    position: relative;
    top: -350px;
}



.ImageGrid {
    margin: 0 9%;
    margin-top: 3%;
}

.color_cards_block{
    margin: 0 9%;
    margin-top: 20%;
}


.Testimonial_Carousel {
    margin: 0 9%;
    margin-top: 20%;
}

.heading1 {
    display: flex;
    flex-direction: column;
  width: 100%;
    margin: 2vh auto;
    margin-bottom: 2rem;
}


.heading1_h {
    font-size: 5.3rem;
    color: var(--heading-color1); 
     width: 50%;
     margin: 0 auto;
    text-align: center;
    font-family: Poppins;
font-style: normal;
font-weight: 100;
    margin-bottom: 1%;
    margin-top: 4vh ;
}

.heading1_p {
    font-size: 1.05rem;
    color: var(--secondary);
    width: 52%;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0.01em;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 5%;
    margin-top: 0;
}

.bold {
    font-weight: 600; 
}
.searchContainer{
    width: 70%;
    justify-content: center;
    align-content: center;
    margin: 4rem auto;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    background:  var(--backgroud-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.seachBox{
    width: 100%;
    height: 4rem;
    background:  var(--backgroud-color);
    border:none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    padding-left: 1.5rem;
}
.seachBox:focus , .seachBox:active , .seachBox:focus-visible{
    outline: none;
}
.searchBtn{
    width: 6rem;
    background:  var(--button-gradient1);
    border: none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.wave1 {
    position: absolute;
    top: 420px;
    overflow: hidden;
}

.wave2 {
    margin-left: 69%;
    position: absolute;
    top: 1220px;
    overflow: hidden;
}

.wave3 {
    position: absolute;
    top: 3350px;
    margin-left: 74%;
    overflow: hidden;
}
@media only screen and (min-width: 1209px) and (max-width: 1388px){
    .wave3 {
        top: 3200px;
    }
  }
.wave4 {
    position: absolute;
    top: 6450px;
    z-index: 1;
    left: -45px;
    overflow: hidden;
}

.wave5 {
    position: absolute;
    top: 8400px;
}

.shape1{
    position: relative;
    margin-left: 10%;
    top: -600px;
}

.shape2{
    position: relative;
    margin-left: 30%;
    top: -1000px;
}


.shape3{
    position: relative;
    margin-left: 85%;
    top: -700px;
}

@media only screen and (max-width: 786px){
    .shape1{
       display: none;
    }
    
    .shape2{
        display: none;
     }
    
    
     .shape3{
        display: none;
     }

     .ImageGrid {
         margin: 10% 1%;
     }

     .color_cards_block{
        margin: 0 2%;
        margin-top: 20%;
    }

    .text_carousel {
        margin: 0 2%;
        margin-top: 20%;
    }

    .Character_Text_Grid {
        margin-left: 4%;
        margin-right: 9%;
        margin-top: 20%;
    }

    .feature_scroll_cards {
        margin: 15% 2%;
        position: static;
    }

    .Testimonial_Carousel {
        margin: 0 2%;
        margin-top: 20%;
    }
    .screenCont{
        padding-left: 1vw;
    }
    .heading1_h {
        font-size: 3.3rem;
        color: var(--heading-color1); 
         width: 50%;
    }
}


@media only screen and (max-width: 909px){
    .mobDash{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        justify-items: center;
        margin: 0 auto;
    }
    .mobileHeading1_h{
        font-size: 2rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: 100;
        color: var(--heading-color2);
    }
}
@media only screen and (min-width: 789px) and (max-width: 1188px){
   
    .heading1_h{
        font-size: 4.3rem;
    }
  }