.card {
    min-width: 50%;
    display: flex;
}

@media (max-width: 768px) {
    .card {
        flex-wrap: wrap;
        margin-bottom: 5%;
    }
}


.image_card {
    background: #0D0F22;
    min-width: 30%;
    padding: 5% 0%;
    border-radius: 3rem;
    height: fit-content;
}

@media (max-width: 768px) {
    .image_card {
        padding: 5% 5%;
    }
}



.image {
    text-align: center;
    vertical-align: middle;
    height: 100%;
}


.text_box {
    margin-left: 2%;
    padding: 0% 5%;
}

@media (max-width: 768px) {
    .text_box {
        padding: 0;
    }
}



.text_title {
    font-size: 1.25rem;
    color: #D9DEF4;
    font-weight: 500;
}

.text {
    color: #7F89BE;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}
