.heading1 {
    display: flex;
    flex-direction: column;
    width: 72%;
    margin: 4.5rem auto;
    margin-bottom: 2rem;
}

.heading3 {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 2rem auto;
    margin-bottom: 9%;
}

@media only screen and (max-width: 698px){
    .heading3 {
        margin-bottom: 15%;
        width: 90%;
    }
}


.heading4 {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 2rem auto;
    margin-bottom: 9%;
}

@media only screen and (max-width: 698px){
    .heading4 {
        margin: 0;
        width: 100%;
    }
}


.heading1_h {
    font-size: 5rem;
    color: white;
    text-align: center;
    font-weight: 200;
    margin-bottom: 2%;
}

@media (max-width: 768px) {
    .heading1_h  {
        font-size: 2rem;
    }
}




.heading1_p {
    font-size: 1rem;
    color: #7F89BE;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 5%;
    margin-top: 0;
}

.heading2_h {
    font-size: 4.5rem;
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    margin-bottom: 2%;
    margin-top: 0;
}

@media (max-width: 768px) {
    .heading2_h  {
        font-size: 2rem;
        text-align: left;
    }
}


.heading2_p {
    font-size: 1.5rem;
    color: #7F89BE;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-bottom: 5%;
    margin-top: 0;
}

@media (max-width: 768px) {
    .heading2_p  {
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
    }
}

.bold {
    font-weight: 600; 
}

.heading1_button {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
}

.heading1_button_arrow {
   font-size: 1.5rem;
   padding: 1rem;
}

@media only screen and (min-width: 789px) and (max-width: 1188px){
   
    .heading1_h , .heading2_h , .heading3_h, .heading4_h {
        font-size: 3.3rem;
    }
  }