.Transparent_Blue_Button{
    border-radius: 100rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    padding: 0.75rem 1.75rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, #00C6FF 35%, #0072FF );
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px#0D0F22 inset; 
    cursor: pointer;
}

.button_text {
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#00C6FF, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}
@media only screen and (min-width: 889px) and (max-width: 920px) {
  .Transparent_Blue_Button{
      padding: 0.65rem 1.05rem;
  }
}