.image_slide {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 100%;
    font-family: 'Poppins', sans-serif;
    transition-duration: 1s;
}

.image {
    width: 90%;
    border-radius: 3rem;
    min-height: 40%;
    max-height: 40%;
    background-repeat: no-repeat;
    padding-top: 120%;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    transition-duration: 1s;
}


.blur {
    opacity:0.4;
    filter:alpha(opacity=40);
}

.blur_image {
    padding-top: 100%;
}


.name {
    font-size: 2.5rem;
    color: white;
    transition-duration: 1s;
}

.quote {
    font-size: 1.5rem;
    color: #7F89BE;
    transition-duration: 1s;

}

@media (max-width: 786px) {
    .image_slide {
        min-width: 60%;
    }
    .name {
        font-size: 1rem;
        text-align: left;
        margin-left: 10%;
        font-weight: 500;
        margin-bottom: 0;
    }
    .quote {
        font-size: 0.75rem;
        text-align: left;
        margin-left: 10%;
    }

    .image {
        padding-top: 90%;
        width: 80%;
    }

    .blur_image {
        padding-top: 70%;
    }
}


