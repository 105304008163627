.Hashtag {
    border-radius: 100rem;
    padding: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    padding: .5rem 1.5rem;
    color: white;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, #00C6FF 35%, #0072FF );
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px#121528 inset;
}

.button_text {
    color: transparent;
    background-clip: text;
    background: -webkit-linear-gradient(left,#00C6FF, #0072FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}


.Hashtag:hover {
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, #00C6FF 35%, #0072FF );
    background-clip: border-box;
    box-shadow: none;
    cursor: pointer;
}

.Hashtag:hover .button_text {
    background: none;
    -webkit-text-fill-color: var(--text-primary);
}