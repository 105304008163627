.feature_card {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    color: var(--text-primary);
    padding: 1rem 1rem;
    justify-content: space-around;
    color: var(--secondary);
    margin: 0.6rem 0.6rem;  
    animation: 1s;
    transition-duration: 1s;
    box-shadow: 0px 4px 10px rgba(0, 178, 255, 0.04);
border-radius: 16px;
    max-height: 11rem;
    min-height: 11rem;

}


.red_border {
    border: 1px solid #E42A5B;
}

.green_border {
    border: 1px solid #BDF2A5;
}

.yellow_border {
    border: 1px solid #F5D495;
}

.blue_border {
    border: 1px solid #00C6FF;
}
.sideborder{
    border: 1px solid rgba(0, 200, 255, 0.705) !important;
    box-shadow: 0px 4px 15px rgba(0, 198, 255, 0.1) !important;
}

.username {
    display: flex;
    flex-direction: row;
    font-size: 0.7rem;
    align-content: center;
    align-items: center;
    margin-bottom: 0.9rem;
    color: var(--heading-color2);
    
}

.content {
    font-size: 0.8rem;
    color: var(--heading-color2);
    margin-bottom: 1rem;
}


.info_box {
    display: flex;
    justify-content: space-between;
    font-size: 0.65rem;
    color: var(--heading-color2);
}

.info {
    display: flex;
    justify-content: space-around;
}


.info_element {
    display: flex;
    margin-right: 1rem;
}