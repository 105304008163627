.title_button_block{
    margin: 0 auto;
}

.heading1_p {
    font-size: 1.05rem;
    color: var(--secondary);
    width: 52%;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0.01em;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 5%;
    margin-top: 0;
}

.bold {
    font-weight: 600;
  
}
.heading1 {
    display: flex;
    flex-direction: column;
  width: 100%;
    margin: 2vh auto;
    margin-bottom: 2rem;
}


.heading1_h {
    font-size: 5.3rem;
    color: var(--heading-color1); 
     width: 50%;
     margin: 0 auto;
    text-align: center;
    font-family: Poppins;
font-style: normal;
font-weight: 100;
    margin-bottom: 1%;
    margin-top: 4vh ;
}

.searchContainer{
    width: 70%;
    justify-content: center;
    align-content: center;
    margin: 4rem auto;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    background:  var(--backgroud-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.seachBox{
    width: 100%;
    height: 4rem;
    background:  var(--backgroud-color);
    border:none;
    color: var(--text-primary);
    font-family: var(font-family);
font-style: normal;
font-weight: normal;
font-size: 1rem;
    padding-left: 1.5rem;
}
.seachBox:focus , .seachBox:active , .seachBox:focus-visible{
    outline: none;
}
.searchBtn{
    width: 6rem;
    background:  var(--button-gradient1);
    border: none;
    color: var(--text-primary);
    font-family: var(font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

@media only screen and (max-width: 788px){
    .title_button_block{
        margin: 0 0rem;
        width: 98%;
    }
    .heading1{
        margin-top: 3.5rem;
        margin-left: 1rem;
    }
    .heading1_p {
        color: var(--secondary);
        width: 100%;
        margin: 0 0;
        text-align: left;
        margin-bottom: 5%;
        margin-top: 1rem;
    }
    .bold{
        background: -webkit-linear-gradient(var(--text-gradient));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      color: var(--button-gradient1) !important;
    }
    .heading1_h{
        width: 95%;
        margin: 0 0 ;
        text-align: left;
        font-size: 3.3rem;
    }
    .searchContainer{
        width: 80%;
        margin: 2rem auto;
    }
    .seachBox{
        height: 3rem;
    }
  }
  @media only screen and (min-width: 789px) and (max-width: 1188px){
   
    .bold{
        background: -webkit-linear-gradient(var(--text-gradient));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      color: var(--button-gradient1) !important;
    }
    .heading1_h{
        font-size: 4.3rem;
    }
  }