.Blog_Card_Container {
    display: flex;
    justify-content: space-evenly;
    margin: 9%;
    border-radius: 2rem;
    flex-wrap: wrap;
}



@media (max-width: 768px) {
    .Blog_Card_Container {
        flex-direction: column;
    }
}