.container1 {
    margin: 2.5% 9%;
    max-height: 30%;
    display: flex;
    justify-content: space-between;
}

.container2 {
    margin: 4% 9%;
    max-height: 30%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .container1 {
        flex-direction: column;
    }

    .container2 {
        flex-direction: column;
        flex-flow: column-reverse;
    }
}

.Color_Card {
    padding: 10%;
    border-radius: 6rem;
    background: #0D0F22;
    height: fit-content;
}


@media (max-width: 768px) {
    
    .Color_Card {
        padding: 10% 0;
        width: 150px;
        text-align: center;
        border-radius: 3rem;
    }
}

@media (max-width: 500px) {
    
    .Color_Card {
        padding: 13% 0;
    }
}


.image {
    text-align: center;
    vertical-align: middle;
}


.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left_margin {
    margin-left: 10%;
}

.right_margin {
    margin-right: 10%;
}

@media (max-width: 768px) {
    .left_margin {
        margin: 0;
    }

    .right_margin {
        margin: 0;
    }
}


.title {
    font-size: 3rem;
    font-weight: 200;
}

@media (max-width: 768px) {
    .title {
        font-size: 1.25rem;
        font-weight: 500;
    }
}

.caption {
    font-size: 1.25rem;
    color: var(--secondary);
    margin-top: 0;
}


@media (max-width: 768px) {
    
    .caption {
        margin-top: 0;
        font-size: 1rem;
    }
    
    .img {
        height: 48px;
    }
}